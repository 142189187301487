<template>
  <cw-page
    icon="work"
    class="cw-sme-pos-start"
  >
    <template #hero-title>
      <translate>
        Welcome to Saldo Corporate loan application!
      </translate>
    </template>
    <v-row>
      <v-col
        cols="24"
        md="12"
      >
        <v-alert
          border="left"
          class="confirmation__terms mb-0"
          color="primary"
          text
        >
          <h3 class="text-subtitle-1 font-weight-bold mb-1">
            <translate>
              Yrityslainojen myöntäminen on päättynyt.
            </translate>
          </h3>
          <p>
            <translate>
              Tact Finance Oy on lopettanut yrityslainojen myöntämisen marraskuussa 2024. Uusia
              yrityslainoja voi edelleen hakea Saldo Bankilta osoitteessa
            </translate>
            <a href="https://www.saldo.com/fi-fi/yritysasiakkaat/yrityslaina/">www.saldo.com</a>
          </p>
        </v-alert>
      </v-col>
      <v-col
        md="12"
        cols="24"
      />
    </v-row>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwSmePosMerchantStart',

  mixins: [goToError],

  data: () => ({
    form: {
      appliedLoanLimit: null,
      appliedInstalmentCount: null,
      businessId: '',
      smeProductName: '',
    },
    pv: null,
    instalmentCounts: [...Array(60).keys()].map(x => x + 1),
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
    }),
    appliedLoanLimitLabel: vm => vm.$gettext('Loan amount'),
    instalmentCountLabel: vm => vm.$gettext('The loan period'),
    businessIdLabel: vm => vm.$gettext('Business id'),
    smeProductNameLabel: vm => vm.$gettext('Product to be purchased'),
  },

  watch: {
    initialData: {
      handler(data) {
        if (data.appliedLoanLimit) {
          this.form.appliedLoanLimit = parseInt(data.appliedLoanLimit, 10);
        }

        if (data.appliedInstalmentCount) {
          this.form.appliedInstalmentCount = parseInt(data.appliedInstalmentCount, 10);
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),
    async onInput() {
      this.pv = ((this.form.appliedLoanLimit + (this.form.appliedLoanLimit * 0.049)) / 100);
      console.log(this.pv);
      console.log(this.form.appliedInstalmentCount);
    },
    async validateForm() {
      const valid = await this.$refs.observer.validate();

      DataLayer.onValidationClick();

      if (!valid) {
        console.log('form invalid');
        this.goToError();
        DataLayer.onValidationFail();
        return;
      }

      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');
      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Start',
      };

      try {
        await this.submitForm({
          formParams: this.form,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}
th {
  text-align: left;
  font-weight: 400;
}
table.table {
  width: 100%;
  font-weight: 300;
}
</style>
